export default function setPrerenderReady(){
    const isShowPreloader = () =>{
        return document.querySelector('.preloader') && !document.querySelector('.preloader--hidden');
    };

    function setPreloaderInterval() {
        let preloaderInterval = setInterval(function(){
            clearInterval(preloaderInterval);
            preloaderInterval = null;
            if (!isShowPreloader()) {
                window.prerenderReady = true;
            } else setPreloaderInterval();
        }, 1000);
    }


    setTimeout(function(){
        if (isShowPreloader()) {
            setPreloaderInterval();
        } else {
            window.prerenderReady = true;
        }
    }, 4000);

};