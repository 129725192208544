import React, {Fragment, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import Cookies from "js-cookie";
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty';
import { TechnicalMessage } from "@s7/ui-kit";
import { injectIntl } from '../../Intl';
import {getSiteAlerts} from "../../services/api/api";
import { analyticsAlertClick, analyticsAlertClose, analyticsAlertShow } from '../../services/analytics/analyticsAlerts';
import '../../assets/styles/components/site-alerts.scss';

const SiteAlerts = props => {
    const { lang, profile, allSupportedLanguages, getMessage, words } = props;
    const [ allAlerts, setAllAlerts ] = useState([]);
    const [ alerts, setAlerts ] = useState([]);
    const [ isAlertsLoad, setAlertsLoad ] = useState(false);
    const [ currentAlert, setCurrentAlert ] = useState('');
    const history = useHistory()

    useEffect(() => {
        if (isAlertsLoad && !isEmpty(alerts)) {
            let elements = document.querySelectorAll(".alert-item");
            if (!!elements?.length) {
                for (let i = 0; i < alerts.length; i++) {
                    let link = elements[i].querySelectorAll("a");
                    for (let iLink = 0; iLink < link?.length; iLink++) {
                        link[iLink].onclick = function () {
                            analyticsAlertClick(alerts[i].title);
                        };
                    }
                }
            }
        }
    }, [isAlertsLoad]);

    useEffect(() => {
        history.listen((location) => {
            loadAlerts();
        })
    }, [history])

    useEffect(() => {
        loadAlerts()
    }, []);

    useEffect(() => {
        if (allAlerts.length > 0) {
            filterAlerts(allAlerts);
        }
    }, [profile])

    const loadAlerts = () => {
        const s7PageInit = typeof(window.s7PageInit)!=='undefined' ? window.s7PageInit : {};
        let siteAlerts = [];

        if (s7PageInit.hasOwnProperty('alert') && s7PageInit.alert.hasOwnProperty(lang)) {
            siteAlerts = s7PageInit.alert[lang];

            if (siteAlerts.length > 0) {
                setAllAlerts(siteAlerts);
                filterAlerts(siteAlerts);
            }
        } else {
            let version = -1;
            if(typeof(s7ContentVersion)!='undefined' && s7ContentVersion.hasOwnProperty('alert')){version=s7ContentVersion['alert'];}

            getSiteAlerts(lang, version).then(data => {
                let siteAlerts = data ?? [];

                if (siteAlerts.length > 0) {
                    setAllAlerts(siteAlerts);
                    filterAlerts(siteAlerts);
                }
            });
        }
    }

    const filterAlerts = (alerts) => {
        const nowDate = Date.now();
        const userAirport = Cookies.get('airport');
        let siteAlerts = alerts.filter((alert) => {
            let airports = alert?.targeting?.userAirport ?? []; 

            const isTargetingByAirport = isAlertForUser(airports, userAirport);
            if(!isTargetingByAirport) return false;

            const isTargetingByDate = isAlertForNowDay(nowDate, alert.publishedFrom, alert.publishedTo);
            if(!isTargetingByDate) return false;

            const isTargetingByTypes = filterProfileTypes(alert);
            if(!isTargetingByTypes) return false;
            
            const isTargetingB2b = filterTargetingB2b(alert);
            if(!isTargetingB2b) return false;


            const isTargetingBySsdkl = filterSsdkl(alert);
            if(!isTargetingBySsdkl) return false;
            
            const isTargetingByPlacement = filterPlacement(alert);
            if(!isTargetingByPlacement) return false;

            return true;
        });

        const transformedData = siteAlerts.map(( alert, index ) => {
            const alertType = alert.type && alert.type.toLowerCase();
            return {
                id: index,
                content: <div dangerouslySetInnerHTML={{__html: get(alert, 'content', '')}} className={`alert-item alert-item__type_${alertType}`}/>,
                type: alertType,
                hideCloseButton: alert.hideCloseButton
            };
        });
        setAlerts(transformedData);
    }

    const filterProfileTypes = (alert) => {
        let curProfileType = profile?.cardNumber ? 'FFP' : (!isEmpty(profile) && !profile.cardNumber) ? 'SIMPLE' : 'UNAUTHORIZED';
        let profileTypes = alert && alert.targeting && alert.targeting.profileTypes ? alert.targeting.profileTypes : [];
        return profileTypes.includes(curProfileType);
    }
    const filterTargetingB2b = (alert) => {
      let targetingB2bUser = alert && alert.targeting && alert.targeting.b2bUser ? alert.targeting.b2bUser : [];
      if(targetingB2bUser.includes("NOT_SUPPORTED")) return !(profile && !isEmpty(profile.b2b));
      if(targetingB2bUser.includes("SUPPORTED")) return profile && !isEmpty(profile.b2b);
      else return true;
    };

    const filterSsdkl = (alert) => {
        let ssdkl = Cookies.get('ssdkl');
        let ssdklAll = alert?.targeting?.ssdklList ?? '';
        let ssdklList = ssdklAll.replace(' ','').split(',');
        return ssdklAll.length === 0 || ssdklList.includes(ssdkl);
    }

    const filterPlacement = (alert) => {
        const pathname = window.location.pathname;
        const isMainPage = (pathname.length === 1 && pathname === '/' || pathname.length === 4 && pathname.match(allSupportedLanguages));
        let placement = alert?.webSettings?.placement ?? 'ALL_PAGES';
        switch (placement) {
            case ('ALL_PAGES'): return true;
            case ('MAIN_PAGE') : return isMainPage;
            case ('SPECIFIED_PAGES'): {
                let showAlert = false;
                let urls = alert?.webSettings?.urls ?? '';
                urls = urls.split('\r\n');
                let excludedUrls = alert?.webSettings?.excludedUrls ?? '';
                excludedUrls = excludedUrls.split('\r\n');

                urls.forEach(url => {
                    if (url && window.location.href.match(url)) {
                        showAlert = true;
                    }
                });
                excludedUrls.forEach(url => {
                    if (url && window.location.href.match(url)) {
                        showAlert = false;
                    }
                });
                return showAlert;
            }
        }
    };

    const isAlertForUser = (airports, userAirport) => {
        if (isEmpty(userAirport) && isEmpty(airports.filter(airport => !airport.startsWith('!')))) {
            return true;
        }
        if (isEmpty(airports)) {
            return true;
        }
        if (airports.toString().includes('!')) {
            return !airports.includes(`!${userAirport}`);
        } else {
            return airports.includes(userAirport);
        }
    };

    const isAlertForNowDay = (now, startDate, expireDate) => {
        //Пример даты "2020-02-06 16:00:00"
        if (startDate && typeof(startDate) === 'string' && startDate.length >= 19) {
            const startTime = Date.parse(startDate);
            if(startTime > now) {
                return false;
            }
        }

        if (expireDate && typeof(expireDate) === 'string' && expireDate.length >= 19 ) {
            const expireTime = Date.parse(expireDate);
            if(expireTime < now) {
                return false;
            }
        }

        return true;
    };

    const onCloseAlert = deletedAlert => {
        const newAlerts = alerts.filter(alert => alert.id !== deletedAlert.id);
        newAlerts.hideCloseButton = false;
        setAlerts(newAlerts);
        analyticsAlertClose(deletedAlert.title);
    }

    if (alerts && alerts.length === 0) {
        return null;
    }

    return (
        <Fragment>
            {alerts && getMessage(words, 'com.s7.alerts.ofText') &&
                <TechnicalMessage
                    classes={{root: `alert-block hide-alert-block-on-thematic-page`}}
                    alerts={alerts}
                    onClose={onCloseAlert}
                    onShow={(alert) => {
                        if (!isAlertsLoad) {
                            setAlertsLoad(true);
                        }
                        if (currentAlert !== alert.title) {
                            analyticsAlertShow(alert.title);
                            setCurrentAlert(alert.title);
                        }
                    }}
                    ofText={getMessage(words, 'com.s7.alerts.ofText')}
                />
            }
        </Fragment>
    );
}

const mapStateToProps = state => ({
    lang: state.App.lang,
    profile: state.User.profile
});

export default connect(mapStateToProps)(injectIntl(SiteAlerts));