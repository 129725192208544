import React, {Component} from 'react';
import 'url-polyfill';
import 'whatwg-fetch';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import 'intersection-observer';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/zh-cn';

import './assets/styles/reset.scss';
import './assets/styles/layout.scss';
import './assets/styles/global.scss';
import './assets/styles/components/button.scss';
import './App.scss';
import Layout from './components/common/Layout';
import {setBreadcrumbs} from './services/actions/NavigationActions';
import {injectIntl} from './Intl';
import PageLoader from './components/loaders/PageLoader';
import {setSupportedLanguages} from "@s7ui-kit/s7ui-kit";
import { s7PageInit } from './services/utils/CDNPageInit';
import { ThemeProvider } from "@s7/styles";

const NotFound = React.lazy(() => import("./pages/NotFound"));
const TimeTable = React.lazy(() => import('./pages/Timetable'));
const TimetableFlight = React.lazy(() => import('./pages/TimetableFlight'));
//const MainPage = React.lazy(() => import('./pages/Main'));
const GamificationLanding = React.lazy(() => import('./pages/GamificationLanding'));
const S7EventsForm = React.lazy(() => import('./pages/S7EventsForm'));
const ClaimForm = React.lazy(() => import('./pages/ClaimForm'));
const Fifa2018 = React.lazy(() => import('./pages/Fifa2018'));
const MysterypaxLanding = React.lazy(() => import('./pages/MysterypaxLanding'));
//const FfpStatusLanding = React.lazy(() => import('./pages/Landings/FfpStatusLanding'));
const TravelWithChild = React.lazy(() => import('./pages/TravelWithChild'));
// const IconPreview = React.lazy(() => import('./components/common/Icons'));
const FormulaLanding = React.lazy(() => import('./pages/Landings/FormulaLanding'));
const TenYearsPriority = React.lazy(() => import('./pages/Landings/10YearsPriority'));
const Flightform = React.lazy(() => import('./pages/Flightform'));
const BoeingMax = React.lazy(() => import('./pages/Landings/boeingMax'));
const LandingSwitcher = React.lazy(() => import('./pages/LandingSwitcher'));
const AboutBrand = React.lazy(() => import('./pages/AboutBrand'));
const SpecialMeals = React.lazy(() => import('./pages/SpecialMeals'));
const S7Priority = React.lazy(() => import('./pages/S7Priority'));
const Tablo = React.lazy(() => import('./pages/Tablo'));
const TravelWithPets = React.lazy(() => import('./pages/TravelWithPets'));
const Offers = React.lazy(() => import('./pages/Offers'));
const SpecialOffer = React.lazy(() => import('./pages/SpecialOffer'));
const NewsPage = React.lazy(() => import('./pages/NewsPage'));
const PriorityTickets = React.lazy(() => import('./pages/PriorityTickets'));
const TimeToFly = React.lazy(() => import('./pages/TimeToFly'));
const TestBot = React.lazy(() => import('./pages/TestBot'));
const News = React.lazy(() => import('./pages/News'));
const SibirForest = React.lazy(() => import('./pages/sibirForest'));
const ConfirmPublicOffer = React.lazy(() => import('./pages/ConfirmPublicOffer'));
const NewMainPage = React.lazy(() => import('./pages/NewMainPage'));
const MilesCash = React.lazy(() => import('./pages/MilesCash'));
//const TestAppAuth = React.lazy(() => import('./pages/TestAppAuth'));
const Pages = React.lazy(() => import('./pages/Pages'));
const Offices = React.lazy(() => import('./pages/Offices'));
const RoutesLanding = React.lazy(() => import('./pages/RoutesLanding'));
const ThematicSearchesMain = React.lazy(() => import('./pages/ThematicSearchesMain'));
const ThematicSearchesDetail = React.lazy(() => import('./pages/ThematicSearchesDetail'));
const SearchResults = React.lazy(() => import('./pages/SearchResults'));
const IwayIframe = React.lazy(() => import('./pages/IwayIframe'));
const HappyNewAir2020 = React.lazy(() => import('./pages/HappyNewAir'));
const VKPage = React.lazy(() => import('./pages/VKPage'));

const mapStateToProps = state => ({
    lang: state.App.lang,
    supportedLanguages: state.App.supportedLanguages,
});

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: params => dispatch(setBreadcrumbs(params)),
        setSupportedLanguages: (languages = ['ru']) => {
            return dispatch(setSupportedLanguages({languages}));
        }
    }
};

moment.updateLocale('ru', {
    months: ['Январь', "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
});

const allSupportedLanguages = '(en|ru|zh)';

class App extends Component {
    isLoading = false;
    state = {
        pageNotFound: {}
    };
    words = {};
    onlyRu = ["ru"]

    constructor(props) {
        super(props);

        if(('pageNotFound' in s7PageInit) ) {
            this.state = {
                pageNotFound : s7PageInit.pageNotFound
            };
        }
    }

    async componentDidMount() {
        const { lang , setSupportedLanguages, setBreadcrumbs} = this.props;
        const critical = document.querySelector('[data-block="critical-css"]');

        moment.locale(lang === 'zh' ? 'zh-cn' : lang);

        if (critical) {
            critical.parentNode.removeChild(critical);
        }

        if (('page' in s7PageInit) && ('_languages' in s7PageInit.page)) {
            setSupportedLanguages(s7PageInit.page['_languages']);
        } else if (('news' in s7PageInit) && ('_languages'
            in s7PageInit.news)) {
            setSupportedLanguages(s7PageInit.news['_languages']);
        } else if (('specialOffer' in s7PageInit) && ('_languages'
            in s7PageInit.specialOffer)) {
            setSupportedLanguages(s7PageInit.specialOffer['_languages']);
        }

    }

    componentDidUpdate(prevProps) {
        const { lang } = this.props;

        if (lang !== prevProps.lang) {
            moment.locale(lang === 'zh' ? 'zh-cn' : lang);
        }
    }

    shouldComponentUpdate(nextProps) {
        const { lang } = this.props;

        return nextProps.lang !== lang;
    }

    render() {
        const { lang, setBreadcrumbs, supportedLanguages } = this.props;
        const { pageNotFound } = this.state;

        if(lang in pageNotFound){
            return (<Router>
                <Layout key={lang} lang={lang} allSupportedLanguages={allSupportedLanguages}>
                    <React.Suspense fallback={<div/>}>
                        <NotFound/>
                    </React.Suspense>
                </Layout>
            </Router>);
        }



        setBreadcrumbs([{ level: 0, key: 'com.s7.new.main-page.title', link: '/' }]);

        if (window.location.host === 'happynewair.s7.ru') {
            if ('page' in s7PageInit) {
                return (<Router>
                    <Layout key={lang} lang={lang} allSupportedLanguages={allSupportedLanguages}>
                        <React.Suspense fallback={<div/>}>
                            <LandingSwitcher/>
                        </React.Suspense>
                    </Layout>
                </Router>);
            }
            return (<Router>
                <Layout key={lang} lang={lang} allSupportedLanguages={allSupportedLanguages}>
                    <React.Suspense fallback={<div/>}>
                        <NotFound/>
                    </React.Suspense>
                </Layout>
            </Router>);
        }

        if (window.location.pathname.indexOf("/aviahotel/") != -1) {
          return (
            <Router>
              <Layout
                key={lang}
                lang={lang}
                allSupportedLanguages={allSupportedLanguages}
                showCurrency={false}
              >
                <React.Suspense fallback={<div/>}>
                    <Route
                    exact
                    path={"/:lang" + allSupportedLanguages + "?/aviahotel/"}
                    render={(props) => (
                        <PageLoader
                        isHomepage={true}
                        lang={props.match.params.lang}
                        supportedLanguages={this.onlyRu}
                        >
                        <NewMainPage
                            experiment={true}
                            showHotelAviaTab={true}
                            {...props}
                        />
                        </PageLoader>
                    )}
                    />
                </React.Suspense>
              </Layout>
            </Router>
          );
        }

        return (
            <ThemeProvider>
            <Router>
                <Layout key={lang} lang={lang} allSupportedLanguages={allSupportedLanguages}  showCurrency={true}>
                    <React.Suspense fallback={<div/>}>
                        <Switch>
                            <Route
                                exact
                                path={'/:lang' + allSupportedLanguages + '?'}
                                render={props => (
                                    <PageLoader
                                        isHomepage={true}
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <NewMainPage showHotelAviaTab={false} {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/letat-vygodno/'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={this.onlyRu}>
                                        <RoutesLanding {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/thematic-searches/'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}>
                                        <ThematicSearchesMain {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/thematic-searches/:iata/'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}>
                                        <ThematicSearchesDetail {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/transfer'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}>
                                        <IwayIframe { ...props } />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                path={'/:lang'+allSupportedLanguages+"?/timetable/:departureLocation/:arrivalLocation"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={supportedLanguages}>
                                        <TimetableFlight {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/timetable"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={supportedLanguages}>
                                        <TimeTable {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/s7-events-form"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={supportedLanguages}>
                                        <S7EventsForm {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route exact path={'/:lang'+allSupportedLanguages+"?/empty"} component={null} key="empty"/>
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/mysterypaxLP"}
                                render={props => (
                                    <PageLoader lang="ru" supportedLanguages={supportedLanguages}>
                                        <MysterypaxLanding {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/offers/go-to-football"}
                                render={props => (
                                    <PageLoader lang="ru" supportedLanguages={['ru']}>
                                        <Fifa2018 {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/badges"}
                                render={props => (
                                    <PageLoader lang="ru" supportedLanguages={['ru']}>
                                        <GamificationLanding {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/flight-form"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={this.onlyRu}
                                    >
                                        <Flightform {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/badges/:imageName"}
                                render={props => (
                                    <PageLoader lang="ru" supportedLanguages={['ru']}>
                                        <GamificationLanding {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/travel-with-kids"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={['ru', 'en']}>
                                        <TravelWithChild {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/claim-form"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={['ru', 'en']}>
                                        <ClaimForm {...props} />
                                    </PageLoader>
                                )}
                            />
                            {/*<Route*/}
                                {/*exact*/}
                                {/*path={'/:lang'+allSupportedLanguages+"?/mobile"}*/}
                                {/*render={props => (*/}
                                    {/*<PageLoader*/}
                                        {/*isHomepage={true}*/}
                                        {/*lang={props.match.params.lang}*/}
                                        {/*supportedLanguages={supportedLanguages}*/}
                                        {/*fallback={<BotFallback/>}*/}
                                    {/*>*/}
                                        {/*<MainPage {...props} />*/}
                                    {/*</PageLoader>*/}
                                {/*)}*/}
                            {/*/>*/}
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/offers/s7-formula1-promo"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={this.onlyRu}>
                                        <FormulaLanding {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/offers/10-years-priority"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={this.onlyRu}>
                                        <TenYearsPriority {...props} />
                                    </PageLoader>
                                )}
                            />
                            {/*<Route exact path="/icons" component={IconPreview}/>*/}
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/boeing-max"}
                                render={props => (
                                    <PageLoader
                                        lang={lang}
                                        supportedLanguages={this.onlyRu}
                                    >
                                        <BoeingMax {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/info/s7-airlines/brand"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <AboutBrand  {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/info/onboard/special-meals"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <SpecialMeals {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/info/tablo"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <Tablo {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/info/travel-with-pets"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang}
                                                supportedLanguages={['ru', 'en', 'zh']}>
                                        <TravelWithPets {...props}/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/test-bot"}
                                render={props => (
                                    <PageLoader lang={props.match.params.lang} supportedLanguages={supportedLanguages}>
                                        <TestBot/>
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/offers"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <Offers {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/news"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <News {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/news/:url"}
                                render={props => {
                                    const { lang, url } = props.match.params;

                                    if(('news' in s7PageInit) && (lang in s7PageInit.news)) {
                                        return (<NewsPage url={url}/>);
                                    }

                                    return (
                                        <PageLoader
                                            lang={lang}
                                            supportedLanguages={supportedLanguages}
                                        >
                                            <NewsPage url={url}/>
                                        </PageLoader>
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/offers/:url"}
                                render={props => {
                                    const { lang, url } = props.match.params;

                                    return (
                                        <PageLoader
                                            lang={lang}
                                            supportedLanguages={supportedLanguages}
                                        >
                                            <SpecialOffer url={url}/>
                                        </PageLoader>
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/s7-priority/news/:url"}
                                render={props => {
                                    const { lang, url } = props.match.params;

                                    return (
                                        <PageLoader
                                            lang={lang}
                                            supportedLanguages={supportedLanguages}
                                        >
                                            <NewsPage url={url}/>
                                        </PageLoader>
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/vl/:partnerCode"}
                                render={props => {
                                    const { lang, partnerCode } = props.match.params;

                                    return (
                                        <PageLoader
                                            lang={lang}
                                            supportedLanguages={['ru', 'en']}
                                        >
                                            <TimeToFly url={partnerCode}/>
                                        </PageLoader>
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/s7-priority/spend/priority-tickets"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <PriorityTickets {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/siberia"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <SibirForest {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/confirm-publicoffer"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <ConfirmPublicOffer {...props} />
                                    </PageLoader>
                                )}
                            />

                            {/*<Route*/}
                                {/*exact*/}
                                {/*path={'/:lang'+allSupportedLanguages+"?/mainpage"}*/}
                                {/*render={props => (*/}
                                    {/*<PageLoader*/}
                                        {/*isHomepage={true}*/}
                                        {/*lang={props.match.params.lang}*/}
                                        {/*supportedLanguages={supportedLanguages}*/}
                                    {/*>*/}
                                        {/*<NewMainPage {...props} />*/}
                                    {/*</PageLoader>*/}
                                {/*)}*/}
                            {/*/>*/}

                            {/*<Route*/}
                                {/*exact*/}
                                {/*path={'/:lang'+allSupportedLanguages+"?/main"}*/}
                                {/*render={props => (*/}
                                    {/*<PageLoader*/}
                                        {/*isHomepage={true}*/}
                                        {/*lang={props.match.params.lang}*/}
                                        {/*supportedLanguages={supportedLanguages}*/}
                                    {/*>*/}
                                        {/*<NewMainPage experiment={true} {...props}/>*/}
                                    {/*</PageLoader>*/}
                                {/*)}*/}
                            {/*/>*/}

                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/s7-priority/miles-cash"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <MilesCash {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/happynewair"}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <HappyNewAir2020 {...props} />
                                    </PageLoader>
                                )}
                            />

                           {/*<Route*/}
                            {/*exact*/}
                            {/*path={'/:lang'+allSupportedLanguages+"?/test-app-auth/"}*/}
                            {/*render={props => (*/}
                                {/*<PageLoader*/}
                                    {/*lang={props.match.params.lang}*/}
                                    {/*supportedLanguages={['ru', 'en']}*/}
                                {/*>*/}
                                    {/*<TestAppAuth {...props} />*/}
                                {/*</PageLoader>*/}
                            {/*)}*/}
                        {/*/>*/}
                           <Route
                                exact
                                path="/:lang?/test-app-auth/"
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={['ru', 'en']}
                                    >
                                        <TestAppAuth {...props} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/offices'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <Offices {...props} lang={props.match.params.lang} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+'?/offices/:cityCode/'}
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <Offices {...props} lang={props.match.params.lang} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path="/:lang?/search"
                                render={props => (
                                    <PageLoader
                                        lang={props.match.params.lang}
                                        supportedLanguages={supportedLanguages}
                                    >
                                        <SearchResults {...props} lang={props.match.params.lang} />
                                    </PageLoader>
                                )}
                            />
                            <Route
                                exact
                                path={'/:lang'+allSupportedLanguages+"?/vkpage"}
                                render={props => (
                                    <PageLoader
                                        lang={lang}
                                        supportedLanguages={['ru']}
                                    >
                                        <VKPage {...props} />
                                    </PageLoader>
                                )}
                                />
                            <Route render={props => ( <LandingSwitcher/> ) } />
                        </Switch>
                    </React.Suspense>
                </Layout>
            </Router>
            </ThemeProvider>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
