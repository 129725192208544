import axios from 'axios';
import md5 from 'crypto-js/md5';
import Cookie from 'js-cookie';

import getCurrentLanguage from '../utils/getCurrentLanguage';

const contentServicePrefix = `${process.env.REACT_APP_PREFIX_CONTENT_SERVICE}/api/v1`;
const frontServicePrefix = `${process.env.REACT_APP_PREFIX_FRONT_SERVICE}/api/v1`;
const locationServicePrefix = `${process.env.REACT_APP_PREFIX_LOCATION_SERVICE}/api/v1`;
const ADS_SERVICE_PREFIX = `${process.env.REACT_APP_PREFIX_ADS_SERVICE}/api/v1`;
const env = process.env.NODE_ENV;

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const getData = async function (getter, state, callback) {
    const data = await getter();

    if (state) {
        this.setState({
            [state]: data,
        });
    }

    if (callback) {
        callback();
    }
};

export const getScheduleFromCities = async () => {
    const lang = getCurrentLanguage({ es: 'en' });

    const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/from/cities`, {
        params: { lang },
    });

    return response.data;
};

export const getTabloFromCities = async () => {
    const lang = getCurrentLanguage({ es: 'en' });

    const response = await axios.get(`${locationServicePrefix}/hermes/airports/from`, {
        params: { lang },
    });

    return response.data;
};

export const getSalesDirectionsByCode = async ({ cmsCode, hermesCode, lang, currency }) => {

    const { data: { items, origAirports, destAirports }} = await axios.get(`/S7WSalesService/api/v1/data?cmsCode=${cmsCode}&currency=${currency}&hermesCode=${hermesCode}&lang=${lang}`);
    const result = {
        directions: items,
        origAirports, destAirports,
    };

    return result;
};

export const getHermesDirectionsByCode = async ({ hermesCode, lang, currency }) => {
    const response = await axios.get(`${locationServicePrefix}/sales/directions/plane?currency=${currency}&hermesCode=${hermesCode}_${lang}&language=${lang}`);

    return response;
};

export const getMinPriceFligth = async ({ from, to, lang, currency }) => {
    const response = await axios.get(`${locationServicePrefix}/prices/min/from/${from}/to/${to}`, {
        params: {
            languageCode: lang,
            currencyCode: currency
        },
    });

    return response.data;
};

export const getAirportsByQueue = async queue => {
    const lang = getCurrentLanguage();

    const response = await axios.get(`/app/LocationService`, {
        params: {
            action: 'get_locations',
            searchType: 'avia',
            str: queue,
            lang,
        },
    });

    const data = response.data.c;

    return data;
};

export const getAirportsByQueueForOtherCompany = async queue => {
    const lang = getCurrentLanguage();

    const response = await axios.get(`/app/LocationService`, {
        params: {
            action: 'get_locations',
            searchType: 'avia',
            otherCompanies: true,
            str: queue,
            lang,
        },
    });

    const data = response.data.c;

    return data;
};

export const getUserLocationsByManyIata = async (iataData, lang) => {
    return await axios({
        url: `${locationServicePrefix}/hermes/location/iata-many?lang=${lang}`,
        method: 'post',
        data: iataData,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getLocationByIata = async iata => {
    const lang = getCurrentLanguage({ es: 'en' });

    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/location/iata-${iata}`, {
            params: { lang },
        });

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getLocationsCitiesByIata = async (iataData, lang) => {
    return await axios({
        url: `${locationServicePrefix}/hermes/location/cities-by-iata?lang=${lang}`,
        method: 'post',
        data: iataData,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getScheduleByStartDate = async (departureLocation, arrivalLocation, date) => {
    const lang = getCurrentLanguage({ es: 'en' });

    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/${departureLocation}/${arrivalLocation}`, {
            params: { lang, date },
        });

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getRouteActiveDates = async (departureLocation, arrivalLocation) => {
    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/${departureLocation}/${arrivalLocation}/dates`);

        return response.data.filter(date => new Date(date) >= (new Date()).setHours(0, 0, 0, 0));
    } catch (error) {
        console.error(error);
    }
};

export const getRouteMinPrice = async (departureLocation, arrivalLocation) => {
    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/${departureLocation}/${arrivalLocation}/min-price`);

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getScheduleLocations = async (departureLocation, settings) => {
    const lang = getCurrentLanguage({ es: 'en' });

    if (!departureLocation) return Promise.reject();

    const options = {
        onlyS7: false,
        withoutVisa: false,
        groupByCity: false,
        ...settings,
    };

    try {
        const { onlyS7, withoutVisa, groupByCity } = options;
        const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/${departureLocation}/cities`, {
            params: {
                lang, onlyS7,  withoutVisa, groupByCity,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getDestinationCities = async (departureLocation) => {
    const lang = getCurrentLanguage({ es: 'en' });

    if (!departureLocation) return Promise.reject();

    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/schedule/route/${departureLocation}/destination-cities`, {
            params: {
                lang
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTabloLocations = async (departureLocation) => {
    const lang = getCurrentLanguage({ es: 'en' });

    if (!departureLocation) return Promise.reject();

    try {
        const response = await axios.get(`${locationServicePrefix}/hermes/airports/from/${departureLocation}/to`, {
            params: {
                lang
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getFormStatusCode = async () => {
    const data = await axios.get('/servlets/formprocess/formsecurity', {
        params: {
            action: 'checkStatus',
        },
    });

    return data.headers['x-form-status'];
};


// export const getProfileData = async () => {
//     let url = '/dotCMS/priority/ajaxProfileService';
//
//     if (env !== 'development') {
//         url = `/ru${url}`;
//     }
//
//     const profile = await axios.get(url, {
//         params: {
//             dispatch: 'getUserInfo',
//         },
//     });
//
//     return profile.data;
// };

// export const loginUser = async ({ username, password }) => {
//     const searchParams = new URLSearchParams('');
//     const xFormCode = await getFormStatusCode();
//
//     searchParams.append('dispatch', 'login');
//     searchParams.append('username', username);
//     searchParams.append('password', password);
//     searchParams.append('xFormCode', xFormCode);
//
//     const data = await axios.post('/dotCMS/priority/ajaxLogin', searchParams.toString());
//
//     if (!data.data.redirectUrl) {
//         const profile = await getProfileData();
//         return [profile, xFormCode];
//     }
//
//     return [ data, xFormCode ];
// };

// export const logout = async () => {
//     return await axios({
//         url: '/api/v1/profile/logout',
//         method: 'post'
//     });
// };

export const sendB2bMail = async (params, success, errorfn) => {
    await axios.post(`${frontServicePrefix}/sendmail/b2bLanding`, params, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(() => {
            success();
        })
        .catch(error => {
            errorfn(error.response.data);
        });
};


export const loadAllCountryList = async ({ languageId } = {}) => {
    const { data } = await axios.get(`${contentServicePrefix}/json/content/ountry`, {
        params: {
            languageId: languageId,
            sorting: 'ountry.firstposition desc, ountry.ountryName',
        },
    });
    return data;
};

export const subscribeEmail = async (email, lang) => {
    return await axios({
        method: 'post',
        url: new URL(`/S7WSubscribeService/api/v1/subscribe?lang=${lang}`, window.location),
        data: {email},
    });
};

export const getContentByContentId = async (contentId, params) => {
    return await axios.get(`/S7WContentService/api/v1/json/content/byId/${contentId}`, params);
};

export const getContentByContentType = async (contentType, params) => {
    return await axios.get(`/S7WContentService/api/v1/json/content/${contentType}`, params);
};

export const getMinPrice = async (fromIata, toIata, months) => {
    try {
        const response = await axios.get(`/S7WPluginFlightWatch/Hermes/price/chart/${fromIata}/${toIata};months=${months}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getMinPriceDirection = async (data) => {
    return await axios({
        url: '/S7WPluginFlightWatch/Hermes/price/min/',
        method: 'post',
        data: data,
    });
};

export const getAirlines = async () => {
    const lang = getCurrentLanguage({ es: 'en' });
    try {
        const response = await axios.get(`/S7WPluginFlightWatch/Hermes/airline;lang=${lang}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const subscribePrice = async (params) => {
    return await axios({
        method: 'post',
        url: `/S7WPluginFlightWatch/Hermes/price/subscribe/`,
        data: params,
    });
};

export const getCarsOffers = async (date, iata, lang) =>  {
    let params = {
        date,
        languageCode: lang
    };
    if (iata) params.iata = iata;

    try {
        const data = await axios.get(`/S7WLocationService/api/v1/sales/offers/auto`, {
            params
        });

        return data;
    } catch (error) {
        console.error(error);

        return {};
    }
};

export const getHotelsOffers = async (date, iata, lang) =>  {
    let params = {
        date,
        languageCode: lang
    };
    if (iata) params.iata = iata;

    try {
        const data = await axios.get(`/S7WLocationService/api/v1/sales/offers/hotels`, {
            params
        });

        return data;
    } catch (error) {
        console.error(error);

        return {};
    }
};


export const getOfficesByCity = (cityAlias, lang) => {
    return axios.get(`/S7WContentService/api/v1/content/offices`, {
        params: { cityAlias, lang }
    });
};

export const searchCities = (lang, query, features) => {
    return axios.get(`/S7WContentService/api/v1/content/offices/search`, {
        params: { lang, query, features }
    }).then(({data}) => data);
};

export const searchOffices = (lang, nameQuery, cityAlias) => {
    return axios.get(`/S7WContentService/api/v1/content/offices/geo`, {
        params: { lang, nameQuery, cityAlias }
    }).then(({data}) => data);
};

export const getSearchResults = (lang, limit, offset, query) => {
    return axios.get('/S7WContentService/api/v1/content/search', {
        params: { lang, limit, offset, query }
    }).then(({data}) => data);
};

export const getFallGalleryPhotos = (limit = 20, ids = [], newFirst = false, winners = false) => {
    return axios.post(`${ADS_SERVICE_PREFIX}/contest/instagram-2020/items`, ids, {
        params: { limit, newFirst, winners }
    }).then(({data}) => data);
}

export const pushFallGalleryLike = (id) => {
    const sign = md5(`like:contest-contestItem:${id}:${Cookie.get('ssdkl')}`).toString();
    return axios.post(`${ADS_SERVICE_PREFIX}/like/contest-contestItem/${id}?`, null, {
        params: { sign }
    }).then(({data}) => data);
}

const cache = {};

export const loadCitiesUpgradeMileCalculator = options => {
    const { lang } = options;
    const key = JSON.stringify(options);

    if (key in cache) {
        return cache[key];
    }

    cache[key] = _loadCitiesUpgradeMileCalculator({ lang });

    return cache[key];
};

const _loadCitiesUpgradeMileCalculator = ({ lang }) => {
    return new Promise(resolve => {
        axios.get('/S7WContentService/api/v1/content/mile-tariffs-emd/from-to-list', {
            params: {
                lang,
                version: 2
            },
        }).then(({data}) => {
            resolve(data);
        });
    });
};

const cacheCost = {};

export const loadCostUpgradeMileCalculator = options => {
    const {lang, from, to} = options;
    const key = JSON.stringify(options);

    if (key in cacheCost) {
        return cacheCost[key];
    }

    cacheCost[key] = _loadCostUpgradeMileCalculator({lang, from, to});

    return cacheCost[key];
};

const _loadCostUpgradeMileCalculator = ({lang, from, to}) => {
    return new Promise(resolve => {
        axios.get('/S7WContentService/api/v1/content/mile-tariffs-emd/', {
            params: {
                lang,
                from,
                to,
                version: 2
            },
        }).then(({data}) => {
            resolve(data);
        });
    });
};

const cacheCountry = {};

export const getLocationByCountry = options => {
    const {lang, country} = options;
    const key = JSON.stringify(options);

    if (key in cacheCountry) {
        return cacheCountry[key];
    }

    cacheCountry[key] = _getLocationByCountry({lang, country});

    return cacheCountry[key];
};

const _getLocationByCountry = ({lang, country}) => {
    return new Promise((resolve, reject) => {
        axios.get('/S7WLocationService/api/v1/hermes/location/airports-by-country', {
            params: {
                lang,
                country
            },
        }).then(({data}) => {
            resolve(data);
        }).catch(e => {
            reject(e);
        });
    });
};

const cacheDistance = {};

export const getDistanceBetweenCities = options => {
    const {lang, from, to, unit } = options;
    const key = JSON.stringify(options);

    if (key in cacheDistance) {
        return cacheDistance[key];
    }

    cacheDistance[key] = _getDistanceBetweenCities({lang, from, to, unit});

    return cacheDistance[key];
};

const _getDistanceBetweenCities = ({lang, from, to, unit = 'KM'}) => {
    return new Promise((resolve, reject) => {
        axios.get('/S7WLocationService/api/v1/hermes/location/distance', {
            params: {
                lang,
                from,
                to,
                unit
            },
        }).then(({data}) => {
            resolve(data);
        }).catch(e => {
            reject(e);
        });
    });
};

export const getNearestDate = options => {
    const {afterWorkDays} = options;
    const key = JSON.stringify(options);

    if (key in cacheCountry) {
        return cacheCountry[key];
    }

    cacheDay[key] = _getNearestDate({afterWorkDays});

    return cacheDay[key];
};

const _getNearestDate = ({afterWorkDays}) => {
    return new Promise((resolve, reject) => {
        axios.get('/S7WClaimFilingService/api/v1/charterForm/nearestDate', {
            params: {
                afterWorkDays
            },
        }).then(({data}) => {
            resolve(data);
        }).catch(e => {
            reject(e);
        });
    });
};

const cacheDay = {};

export const getSiteAlerts = ( lang = 'ru', version = -1 ) => {
    const key = lang.toString() + version.toString();

    if (key in cacheSiteAlerts) {
        return cacheSiteAlerts[key];
    }

    cacheSiteAlerts[key] = _getSiteAlerts(lang, version);

    return cacheSiteAlerts[key];
};

const _getSiteAlerts = ( lang, version ) => {
    return new Promise((resolve, reject) => {
        axios.get(`/S7WContentService/api/v1/content/alerts?lang=${lang}&limit=20&offset=0&version=${version}`
        ).then(({data}) => {
            resolve(data);
        }).catch(e => {
            reject(e);
        });
    });
};

const cacheSiteAlerts = {};
