var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { analytics } from '../../../services/analytics/analyticsManager';
var navigationTopic = analytics.createTopic(function (eventName, payload) { return (__assign({ event_name: eventName }, payload)); });
export var sendClickTabNavigationItem = function (fromTab, toTab) { return function () {
    return navigationTopic.sendEvent('click_cta', {
        flow: fromTab,
        step: toTab,
        result: 'open_tab',
    });
}; };
