import variables from '../variables';

export default {
    '@global': {
      'body': { overflow: 'hidden' },
    },
    
    modalOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: '2999',
      overflowX: 'hidden',
      overflowY: 'auto',
      animation: 'show .5s ease',
    },
    
    '@keyframes show': {
      '0%': {
        display: 'none',
        opacity: 0,
      },
      '1%': {
        display: 'flex',
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  
    modal: {
      position: 'relative',
      backgroundColor: variables.white,
      borderRadius: 6,
      boxShadow: '0 31px 41px 0 rgba(33,43,54,0.2), 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08)',
    },

    modalHeader: {
        position: 'relative',
        padding: '16px 134px 15px 24px',
        fontSize: 20,
        lineHeight: '24px',
        color: variables.black87,
        borderBottom: '1px solid rgba(0, 0, 0, .06)',
        maxWidth: props => props.width || 720,
    },

    modalContent: {
        height: props => props.height || 'auto',
        maxWidth: props => props.width || 720,
        maxHeight: 500,
    },
  
    closeButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 24,
      cursor: 'pointer',
    },
  };