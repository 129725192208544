import {
    SET_BREADSCRUMBS,
    UPDATE_BREADSCRUMBS,
    RESET_BREADCRUMBS,
} from '../constants/Navigation';


export const setBreadcrumbs = breadcrumbs => {
    return {
        type: SET_BREADSCRUMBS,
        payload: breadcrumbs,
    }
};

export const updateBreadcrumbs = breadcrumbs => {
    return {
        type: UPDATE_BREADSCRUMBS,
        payload: breadcrumbs,
    }
};

export const resetBreadcrumbs = () => {
    return {
        type: RESET_BREADCRUMBS,
    }
};
