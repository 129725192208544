import Cookies from 'js-cookie';
import isUndefined from 'lodash/isUndefined';


export default (preferredLanguages = {}) => {
    let lang = Cookies.get('lang');
    if(isUndefined(lang)){
        lang = 'ru'
    }

    const preferredLanguage = preferredLanguages[lang];

    return preferredLanguage ? preferredLanguage : lang;
};
