var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default function pushGA4(eventName, application, flow, settings) {
    var dataToSend = __assign({ 'event': 'ga4', 'event_name': eventName }, settings);
    if (flow) {
        dataToSend.flow = flow;
    }
    if (application) {
        dataToSend.application = application;
    }
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(dataToSend);
    }
}
;
