import React, {useEffect, Fragment} from 'react';
import classNames from 'classnames';

import styles from './page-loader.css';

export default React.memo(() => {
    useEffect(() => {
        document.body.style.minHeight = '100vh';
        return () => {
            document.body.style.minHeight = 'inherit';
        }
    }, []);

    return (
        <Fragment>
            <div className={styles.pageContent}>
                <div className={styles.banner}/>
                <div className={styles.offers}>
                    <div className={styles.offer}/>
                </div>
            </div>
        </Fragment>
    );
}, () => true);
