import { isEmpty } from './utils.ts';

export default function({ names, lang, asObject }) {
    if (isEmpty(names)) {
        return null;
    }

    const russianInfo = names.find(({lang}) => lang === 'ru');
    const foreignInfo = names.find(({lang}) => lang !== 'ru');

    let firstName= '';
    let lastName= '';

    if (lang !== 'ru') {
        firstName = foreignInfo && foreignInfo.firstName ? foreignInfo.firstName :
            russianInfo && russianInfo.firstName ? russianInfo.firstName : '';

        lastName = foreignInfo && foreignInfo.lastName ? foreignInfo.lastName :
            russianInfo && russianInfo.lastName ? russianInfo.lastName : '';
    } else {
        firstName = russianInfo && russianInfo.firstName? russianInfo.firstName :
            foreignInfo && foreignInfo.firstName ? foreignInfo.firstName : '';

        lastName = russianInfo && russianInfo.lastName? russianInfo.lastName :
            foreignInfo && foreignInfo.lastName ? foreignInfo.lastName : '';
    }

    return asObject ? { firstName, lastName } : `${firstName} ${lastName}`;
}