var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var PROFILE_TYPE_FULL = 'FULL';
export var transformProfile = function (profile, locale) {
    var _a;
    var name = profile.names.find(function (item) { return item.lang === locale; });
    return __assign({ firstName: name === null || name === void 0 ? void 0 : name.firstName, lastName: name === null || name === void 0 ? void 0 : name.lastName, avatarUrl: (_a = profile.avatar) === null || _a === void 0 ? void 0 : _a.mainLink }, (profile.customerValue === PROFILE_TYPE_FULL && {
        miles: profile.milesBalance,
    }));
};
