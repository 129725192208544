import React, { Fragment, Component } from 'react';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import { connect } from "react-redux";

import { injectIntl } from '../../Intl';

import '../../assets/styles/components/cookie-policy.scss';


const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const COOKIE_MESSAGE = 'com.s7.cookie.notification.txt.p1';
const COOKIE_BUTTON_ALT = 'com.s7.cookie.notification.close';

const mapStateToProps = state => {
    const { lang } = state.App;

    return {
        lang,
    }
};

const isIE = window.navigator.userAgent.indexOf("MSIE ") > 0;

class CookiePolicy extends Component {
    state = {
        cookiePolicyClosed: Cookie.get('cookiePolicyClosed') === 'true',
    };

    componentDidMount() {
        const cookiePolicyClosed = Cookie.get('cookiePolicyClosed');
        const { getMessage, words } = this.props;

        getMessage(words, COOKIE_MESSAGE);
        getMessage(words, COOKIE_BUTTON_ALT);

        if (typeof cookiePolicyClosed === 'undefined' || cookiePolicyClosed === null){
            Cookie.set('cookiePolicyClosed', false, {expires: 2 * 365, domain: cookieDomain});
        }

        if (location && location.search.indexOf('mobile') > 0) {
            this.setState({ cookiePolicyClosed: true });
        }
    };

    handleEvent = () => {
        Cookie.set('cookiePolicyClosed', true, {expires: 2 * 365, domain: cookieDomain});
        this.setState({ cookiePolicyClosed: true });
    };

    render(){
        const { words, getMessage } = this.props;
        const { cookiePolicyClosed } = this.state;

        if (cookiePolicyClosed) {
            return null;
        }

        return (
            <Fragment>
                <div className="cookie-usage-helper" dangerouslySetInnerHTML={{
                    __html: getMessage(words, COOKIE_MESSAGE),
                }} />
                <div className={classnames('cookie-usage', {
                    ['cookie-usage-ie'] : isIE
                })}>
                    <div className="cookie-usage-wrapper">
                        <div className="cookie-usage-image-notification">
                            <svg className="cookie-icon-notification" viewBox="0 0 20 20">
                                <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.51 4.48,20 10,20 C15.51,20 20,15.51 20,10 C20,4.48 15.51,0 10,0 M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,5 C9.44,5 9,5.447 9,6 L9,10 C9,10.55 9.44,11 10,11 C10.55,11 11,10.55 11,10 L11,6 C11,5.44 10.55,5 10,5 M10,13 C9.44,13 9,13.44 9,14 C9,14.55 9.44,15 10,15 C10.55,15 11,14.55 11,14 C11,13.44 10.55,13 10,13" />
                            </svg>
                        </div>
                        <div
                            className="cookie-usage-text"
                            dangerouslySetInnerHTML={{
                                __html: getMessage(words, COOKIE_MESSAGE),
                            }}
                        />
                        <button className="cookie-usage-btn js-cookie-usage-btn" onClick={this.handleEvent} title={getMessage(words, COOKIE_BUTTON_ALT)}>
                            <svg className="cookie-icon-close js-cookie-icon-close" viewBox="0 0 20 20">
                                <path d="M10,8.585 L14.29,4.29 C14.68,3.90 15.31,3.90 15.70,4.29 C16.09,4.68 16.09,5.31 15.70,5.70 L11.41,10 L15.70,14.29 C16.09,14.68 16.09,15.31 15.70,15.70 C15.31,16.09 14.68,16.09 14.29,15.70 L10,11.41 L5.70,15.70 C5.31,16.09 4.68,16.09 4.29,15.70 C3.90,15.31 3.90,14.68 4.29,14.29 L8.58,10 L4.292,5.70 C3.90,5.31 3.90,4.68 4.29,4.29 C4.68,3.90 5.31,3.90 5.70,4.29 L10,8.58 Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    };
}

export default injectIntl(connect(mapStateToProps)(CookiePolicy));
