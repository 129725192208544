import React from 'react';
import { FlagsEN, FlagsRU } from '@s7/icons';
export var languages = [
    {
        code: 'en',
        label: 'English',
        icon: React.createElement(FlagsEN, null),
    },
    {
        code: 'ru',
        label: 'Русский',
        icon: React.createElement(FlagsRU, null),
    },
];
