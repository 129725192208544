import { useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';

export const useDetectKeyboardAndroid = (minKeyboardHeight = 300, defaultValue) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);
    const exceptChat = new RegExp('MessageSender');

    useEffect(() => {
        const listener = () => {
            const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height
            if (!exceptChat.test(document.activeElement.className)) {
                if (isKeyboardOpen != newState) {
                    setIsKeyboardOpen(newState);
                }
            }
        };
        if (isAndroid) {
            window.visualViewport.addEventListener('resize', listener);
            return () => {
                window.visualViewport.removeEventListener('resize', listener);
            };
        }

    }, []);

    return isKeyboardOpen;
};
