const LOAD_OFFER_IMAGES = 'LOAD_OFFER_IMAGES';
const SET_OFFER_IMAGE_STATUS_NONE = 'SET_OFFER_IMAGE_STATUS_NONE';
const SET_OFFER_IMAGE_STATUS_PENDING = 'SET_OFFER_IMAGE_STATUS_PENDING';
const SET_OFFER_IMAGE_STATUS_SUCCESS = 'SET_OFFER_IMAGE_STATUS_SUCCESS';
const SET_OFFER_IMAGE_STATUS_FAILURE = 'SET_OFFER_IMAGE_STATUS_FAILURE';

export {
    LOAD_OFFER_IMAGES,
    SET_OFFER_IMAGE_STATUS_NONE,
    SET_OFFER_IMAGE_STATUS_PENDING,
    SET_OFFER_IMAGE_STATUS_SUCCESS,
    SET_OFFER_IMAGE_STATUS_FAILURE,
};
