export var makeid = function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random()
            * charactersLength));
    }
    return result;
};
export var isEmpty = function (value) {
    return value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0);
};
export var shortText = function (text, limit) {
    var textData = text.trim();
    if (text.length <= limit) {
        return text;
    }
    textData = textData.slice(0, limit); // отрезать по лимиту
    var lastSpace = textData.lastIndexOf(' ');
    if (lastSpace > 0) { // нашлась граница слов, укорачиваем
        textData = textData.substring(0, lastSpace);
    }
    return "".concat(textData, "...");
};
export var getYandexID = function () {
    if (process.env.REACT_APP_CMS_API === 'https://www.s7.ru') {
        return 4173733;
    }
    return 87713619;
};
