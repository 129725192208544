export default function (destination, duration = 50, easing = 'linear', distanceToElement = 30, callback) {
    const easings = {
        linear(t) {
            return t;
        },
        easeInQuad(t) {
            return t * t;
        },
        easeOutQuad(t) {
            return t * (2 - t);
        },
        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic(t) {
            return t * t * t;
        },
        easeOutCubic(t) {
            return (--t) * t * t + 1;
        },
        easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart(t) {
            return t * t * t * t;
        },
        easeOutQuart(t) {
            return 1 - (--t) * t * t * t;
        },
        easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        },
        easeInQuint(t) {
            return t * t * t * t * t;
        },
        easeOutQuint(t) {
            return 1 + (--t) * t * t * t * t;
        },
        easeInOutQuint(t) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
        }
    };

    function scrollPageCalc(){
        cancelAnimationFrame(scroll);
        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const windowPosition = window.scrollY || document.documentElement.scrollTop;
        let numberedHeight = 0;

        if (document.getElementsByClassName('header-container')[0]) {
            const headerHeight = window.getComputedStyle(document.getElementsByClassName('header-container')[0], null).getPropertyValue("height");
            numberedHeight = parseInt(headerHeight.slice(0, headerHeight.length - 2), 10);
        }

        if (document.getElementsByClassName('cookie-usage')[0]) {
            const cookieHeight = window.getComputedStyle(document.getElementsByClassName('cookie-usage')[0], null).getPropertyValue("height");
            numberedHeight += parseInt(cookieHeight.slice(0, cookieHeight.length - 2), 10);
        }

        let destinationOffset = typeof destination === 'number' ? destination : destination.getBoundingClientRect().top + windowPosition - distanceToElement - numberedHeight;
        if(destinationOffset < 0 && windowPosition === 0) destinationOffset = 0;
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);

            if (callback) {
                callback();
            }

            return;
        }

        //let stopFlag = false;

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));
            const timeFunction = easings[easing](time);
            const step = Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start);
            const pageYOffsetRound = Math.round(window.pageYOffset);

            window.scroll(0, step);

            if (pageYOffsetRound === destinationOffsetToScroll || (pageYOffsetRound === 0 && destinationOffsetToScroll < 0) || Math.abs(pageYOffsetRound - destinationOffsetToScroll) < 10) {
                //stopFlag = true;
                if (callback) {
                    callback();
                }

                cancelAnimationFrame(scroll);
                return;
            }

            cancelAnimationFrame(scroll);
            requestAnimationFrame(scroll);
        }

        scroll();
        // setTimeout(() => {
        //   stopFlag = true;
        // }, duration + 50)
    }

    let intervalWindow = setInterval(function () {
        if (document.readyState === 'complete') {
            clearInterval(intervalWindow);
            setTimeout(function () {
                scrollPageCalc();
            }, 0);
        }
    }, 500);
}

