import { SET_PAGE_INFO } from "../constants/PageInfo";

const initialState = {
    pageInfo: {}
};

export default function PageInfo(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_INFO:
            return {state, pageInfo: action.payload};
        default:
            return state
    }
}