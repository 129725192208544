import {
    LOAD_OFFER_IMAGES,
    SET_OFFER_IMAGE_STATUS_FAILURE,
    SET_OFFER_IMAGE_STATUS_PENDING,
} from '../constants/OfferImages';


const initialState = {
    status: 'NONE',
    images: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OFFER_IMAGE_STATUS_PENDING:
            return {...state,
                status: 'PENDING',
            };
        case SET_OFFER_IMAGE_STATUS_FAILURE:
            return {...state,
                status: 'FAILURE',
            };
        case LOAD_OFFER_IMAGES:
            return {...state,
                images: action.payload.images,
                lang: action.payload.lang,
                status: 'SUCCESS',
            };
        default:
            return state;
    }
};
