import React, { useEffect, useState } from 'react';
import pushGA from '../../services/utils/pushGA';
import { injectIntl } from '../../Intl';
import ModalWindow from './../../containers/ModalWindow';
import Cookies from 'js-cookie';
import Chrome from '../../assets/icon/ChromeLogo.svg';
import Firefox from '../../assets/icon/Firefoxlogo.svg';
import Opera from '../../assets/icon/OperaLogo.svg';

import { Download } from '@s7/ui-kit/icons'

import '../../assets/styles/components/modal-update-browser.scss';

const ModalUpdateBrowser = props => {
    const { getMessage, words } = props;
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const onClosePopup = () => {
        Cookies.set('isBrowserOld', true, { expires: 14 });
        setModalIsOpen(false);
        pushGA('Portal OldBrowserPopUp', 'Close', `PATH: ${window.location.pathname}`, { eventValue: 0, eventInteraction: 0 });
    };

    useEffect(() => {
        gaEventsShow();
    }, []);

    const gaEventsClick = (browser) => {
        pushGA('Portal OldBrowserPopUp', 'Click Download', `PATH: ${window.location.pathname}, BROWSER: ${browser}`, { eventValue: 0, eventInteraction: 0 });
    };
    const gaEventsShow = () => {
        pushGA('Portal OldBrowserPopUp', 'Show', `PATH: ${window.location.pathname}`, { eventValue: 0, eventInteraction: 0 });
    };

    return (
        modalIsOpen &&
        <ModalWindow
            onClose={onClosePopup}
            title={getMessage(words, 'com.s7.browserOld.modal.title')}
            classes={{
                container: "browserOld-modal-container",
                header: "browserOld-modal-header",
                close: "browserOld-modal-close",
            }}
        >
            <div className="upd-browser-text">
                {getMessage(words, 'com.s7.browserOld.modal.text')}
            </div>
            <div className="upd-browser-btn-list">
                <a href="https://www.google.com/intl/ru/chrome/" target="_blank" className="upd-browser-btn" onClick={() => gaEventsClick("Chrome")}>
                    <div className="upd-browser-btn-logo">
                        <Chrome />
                    </div>
                    <div className="upd-browser-btn-text">
                        <div className="upd-browser-btn-text-title">Chrome</div>
                        <div className="upd-browser-btn-text-subtitle">{getMessage(words, 'com.s7.browserOld.modal.download')} <Download width='9px' height='9px' /></div>
                    </div>
                </a>
                <a href="https://www.mozilla.org/ru/firefox/new/" target="_blank" className="upd-browser-btn" onClick={() => gaEventsClick("Firefox")}>
                    <div className="upd-browser-btn-logo">
                        <Firefox />
                    </div>
                    <div className="upd-browser-btn-text">
                        <div className="upd-browser-btn-text-title">Firefox</div>
                        <div className="upd-browser-btn-text-subtitle">{getMessage(words, 'com.s7.browserOld.modal.download')} <Download width='9px' height='9px' /></div>
                    </div>
                </a>
                <a href="https://www.opera.com/ru/download" target="_blank" className="upd-browser-btn" onClick={() => gaEventsClick("Opera")}>
                    <div className="upd-browser-btn-logo">
                        <Opera />
                    </div>
                    <div className="upd-browser-btn-text">
                        <div className="upd-browser-btn-text-title">Opera</div>
                        <div className="upd-browser-btn-text-subtitle">{getMessage(words, 'com.s7.browserOld.modal.download')} <Download width='9px' height='9px' /></div>
                    </div>
                </a>
            </div>
        </ModalWindow>
    )
};


export default injectIntl(ModalUpdateBrowser);