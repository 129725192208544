import React, { useEffect, lazy, Suspense } from 'react';

import DefaultFallback from './DefaultFallback';
import setPrerenderReady from '../../services/utils/setPrerenderReady';

const NotFound = lazy(() => import("../../pages/NotFound"));
const NotSupportedLang = lazy(() => import("../../pages/NotSupportedLang"));

const isLangExist = ({ supportedLanguages, lang }) => {
    if (!lang) {
        return false;
    }

    return supportedLanguages.includes(lang);
};

const PageLoader = ({
    children,
    lang = 'ru',
    supportedLanguages = [ 'en', 'ru', 'zh' ],
    isNotFoundPage = () => false,
    fallback = <DefaultFallback/>,
    isHomepage = false,
}) => {
    useEffect(() => {
        setPrerenderReady();
    },[]);


    if (!isLangExist({lang, supportedLanguages})) {
        return (
            <NotSupportedLang langs={supportedLanguages}/>
        );
    }

    if (isNotFoundPage()) {
        return (
            <NotFound/>
        );
    }

    return (
        <Suspense fallback={fallback}>
            {children}
        </Suspense>
    );
};

const equalsFunction = (prevProps, nextProps) => {
    const prevLangExist = isLangExist(prevProps);
    const nextLangExist = isLangExist(nextProps);
    const langAreEqual = prevProps.lang === nextProps.lang;

    return langAreEqual && prevLangExist === nextLangExist;
};

const MemoLoader = React.memo(PageLoader, equalsFunction);

export default PageLoader;
