const CDN_PATH = removeLastSlash(process.env.REACT_APP_ASSETS_CDN_PATH);

export const s7PageInit = 's7PageInit' in window ? window.s7PageInit : {};

/**
 *
 * @param key
 * @param path
 * @param searchParams передавать только тип URLSearchParams
 * @param success
 * @param fail
 */
export function asyncDownload(scriptId, key, path, searchParams) {
    var id = "asyncDownload_" + scriptId;
    if (!document.getElementById(id)) {
        var js = document.createElement('script');
        js.id = id;
        if (!searchParams) {
            searchParams = "";
        }
        js.src = `${CDN_PATH}${path}?${searchParams.toString()}`;
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(js, fs);
    }
    if ('s7SubscribeOn' in window) {
        return new Promise((resolve, reject) => {
            s7SubscribeOn(key, function(data) {
                resolve({status:200,data:data})
            }, reject);
        });
    }
    return false;
}

function removeLastSlash(val) {
    if (val == '' || val == '/') {
        return '';
    }

    if (val.endsWith('/')) {
        return val.substring(0, val.length - 1);
    }
    return val;
}