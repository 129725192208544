export default {
    lime: 'rgb(196, 214, 0)',
    newlime: '#a7c42a;',
    lime38: 'rgba(196, 214, 0, .38)',

    turquoise: '#3DC2CD',
    turquoise2: 'rgb(174, 190, 2)',

    orange: '#F2A900',

    black06: 'rgba(0, 0, 0, .06)',
    black12: 'rgba(0, 0, 0, .12)',
    black38: 'rgba(0, 0, 0, .38)',
    black54: 'rgba(0, 0, 0, .54)',
    black87: 'rgba(0, 0, 0, .87)',

    white: 'rgba(255, 255, 255, 1)',
    white87: 'rgba(255, 255, 255, .87)',

    red: '#D22630',

    G600: '#97BA1E',
}
