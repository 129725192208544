var PROFILE_DOMAIN = process.env.REACT_APP_PROFILE_DOMAIN;
/**
 * Функция, формирующая ссылку для перехода на профиль.
 * В случае передачи redirectUrl после авторизации будет произведен возврат с профиля назад в приложение, из которого изначально был редирект.
 *
 * @param redirectUrl - Ссылка для возврата после авторизации на профиле (опциональный).
 *
 * @returns Ссылка на профиль.
 * */
export var getProfileLinkByAuthState = function (redirectUrl) {
    if (redirectUrl) {
        return "".concat(PROFILE_DOMAIN, "/login?redirect=").concat(encodeURIComponent(redirectUrl));
    }
    return PROFILE_DOMAIN;
};
