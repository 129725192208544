export default function pushGA (eventCategory, eventAction,  eventLabel, settings) {
    let additionalParameters = {};

    if (settings && settings.additionalParameters) {
        additionalParameters = settings.additionalParameters;
        delete settings.additionalParameters;
    }

    const options = {
        eventInteraction: undefined,
        flightDetail: undefined,
        eventUX: undefined,
        eventValue: undefined,
        gaEvent: undefined,
        ...settings
    };

    const dataToSend = {
        event: options.gaEvent || 'GAevent',
        eventCategory,
        eventAction,
        eventLabel,
        flightDetail: options.flightDetail,
        eventValue: options.eventValue,
        eventUX: options.eventUX,
        eventInteraction: options.eventInteraction,
        ...additionalParameters
    };

    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(dataToSend);
    }
};
