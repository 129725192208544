export const isChromeBelow75 = () => {
    if (!(!!window.chrome && !window.opr && !window.opera && (navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('CriOS') !== -1) && navigator.userAgent.indexOf('Edge') === -1)) {
        return false;
    }

    let pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    if (pieces == null || pieces.length != 5) {
        return false;
    }
    pieces = pieces.map(piece => parseInt(piece, 10));

    if (pieces[1] < 75) {
        return true;
    }
    return false;
};
