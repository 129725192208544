import 'url-polyfill';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../services/reducers';
import { loadInitialData, sheduler } from '@s7ui-kit/s7ui-kit';


export default function configureStore(initialState) {
    let startState = {...initialState};

    const url = new URL(window.location.href);
    const prerender = url.searchParams.get('prerender');

    if (prerender === 'true') {
        if (!startState) {
            startState = {};
        }

        if (!startState.RenderMode) {
            startState.RenderMode = 'PRERENDER';
        }
    }

    const store = createStore(
        rootReducer,
        startState,
        applyMiddleware(loadInitialData, sheduler),
    );

    return store;
};

export const storeInstance = configureStore();
