import React, { useEffect } from "react";
import { injectIntl } from "@s7ui-kit/s7ui-kit";
import { connect } from 'react-redux';
import { Box } from "@s7/base";
import { Skeleton } from "@s7/ui";
import { FooterWidget } from "@s7/footer";
import { clickToCall } from "@s7/click-to-call";
import { isMobile } from "react-device-detect";
import { footerType, } from "../../../services/types/footer";
import { analytics, userId } from '../../../services/analytics/analyticsManager';
var mapStateToProps = function (state) { return ({
    profile: state.User.profile
}); };
var Footer = function (_a) {
    var code = _a.code, lang = _a.lang, clickChatButton = _a.clickChatButton, getMessage = _a.getMessage, words = _a.words, profile = _a.profile;
    var serviceUrl = "".concat(process.env.REACT_APP_MEDIA_API, "/api/v2/footer");
    var ICP_LICENSE = process.env.REACT_APP_ICP_LICENSE;
    var pageProps = window.location.pathname.indexOf("/board/magazine/") !== -1
        ? {
            sx: {
                paddingTop: "3em!important",
            }
        }
        : {};
    useEffect(function () {
        if (profile === null || profile === void 0 ? void 0 : profile.id)
            userId.set(profile.id);
        else
            userId.set(undefined);
    }, [profile]);
    var callCenterClick = function (item, _e) {
        var variant = item.variant;
        if (variant && variant === "call") {
            clickToCall({
                webUrl: process.env.REACT_APP_CLICK_TO_CALL,
            });
        }
        if (variant && variant === "chat") {
            clickChatButton();
        }
    };
    return (React.createElement(Box, { sx: function (_a) {
            var palette = _a.palette;
            return ({
                backgroundColor: palette.background.elementWeak,
            });
        } },
        React.createElement(FooterWidget, { analytics: analytics, service: {
                cache: true,
                code: code,
                device: isMobile ? "MOBILE" : "DESKTOP",
                lang: lang,
                url: serviceUrl,
            }, renderSkeleton: function () { return (React.createElement(Skeleton, { height: code === footerType.SHORT || code === footerType.SHORT_WITHOUT_SOCIAL ? "48px" : "90vh" })); }, handlers: {
                callCenter: {
                    onActionItemClick: function (item, event) {
                        callCenterClick(item, event);
                    },
                },
            }, containerProps: code === footerType.SHORT || code === footerType.SHORT_WITHOUT_SOCIAL
                ? {
                    sx: function (theme) { return ({
                        padding: "8px !important",
                    }); },
                }
                : pageProps }),
        ICP_LICENSE && (React.createElement("a", { href: getMessage(words, "com.s7.rule.copyright.chinalink"), target: "_blank", style: {
                textAlign: "center",
                display: "block",
                color: "rgba(0,0,0,.87)",
                textDecoration: "underline",
                fontSize: "14px",
                padding: "8px 0 16px",
            } }, ICP_LICENSE))));
};
export default connect(mapStateToProps)(injectIntl(React.memo(Footer)));
