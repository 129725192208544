import {
    SET_BREADSCRUMBS,
    UPDATE_BREADSCRUMBS,
    RESET_BREADCRUMBS,
} from '../constants/Navigation';

const initialState = {
    breadcrumbs: [
        {
            level: 0,
            key: 'com.s7.new.main-page.title',
            link: '/',
        }
    ]
};

export default function Navigation(state = initialState, action) {
    switch (action.type) {
        case SET_BREADSCRUMBS:
            return {...state, breadcrumbs: action.payload};
        case UPDATE_BREADSCRUMBS:
            state.breadcrumbs[action.payload.level] = action.payload;
            return {...state, breadcrumbs: [...state.breadcrumbs]};
        case RESET_BREADCRUMBS:
            return {...state, breadcrumbs: initialState.breadcrumbs};
        default:
            return state;
    }
}