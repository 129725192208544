import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseIcon from '../../assets/images/cross_lime.svg';

export const ModalWindow = ({ onRef, children, onClose, classes, title }) => {
    return (
        <div className={classNames(classes.modalOverlay, classes.root)}>
            <div
                className={classNames(classes.modal, classes.container)}
                ref={node => onRef(node)}
            >
                <div className={classNames(classes.modalHeader, classes.header)}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                    <div
                        className={classNames(classes.closeButton, classes.close)}
                        onClick={event => onClose(event)}
                    >
                        <CloseIcon/>
                    </div>
                </div>
                <div className={classNames(classes.modalContent, classes.content)}>
                    { children }
                </div>
            </div>
        </div>
    )
}

ModalWindow.propTypes = {
    onRef: PropTypes.func,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    height: PropTypes.number,
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        content: PropTypes.string,
        header: PropTypes.string,
        close: PropTypes.string
    })
};
