import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/fp/isNil';
import injectSheet from 'react-jss';

import { ModalWindow } from '../components/common/ModalWindow';
import styles from '../assets/styles/components/modal-window';

class Modal extends React.Component {
    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyPress);
        document.addEventListener('click', this.handleOutsideClick, { capture: true });
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyPress);
        document.removeEventListener('click', this.handleOutsideClick, { capture: true });
    }

    handleKeyPress = event => {
        const { onClose } = this.props;
        const keys = {
            27: () => {
                event.preventDefault();
                onClose();
                window.removeEventListener('keyup', this.handleKeyPress);
            },
        };

        if (keys[event.keyCode]) {
            keys[event.keyCode]();
        }
    }

    handleOutsideClick = event => {
        const { onClose } = this.props;

        if (!isNil(this.modal)) {
            if (!this.modal.contains(event.target)) {
                onClose();
                document.removeEventListener('click', this.handleOutsideClick);
            }
        }
    }

    render() {
        return (
            <ModalWindow
                { ...this.props }
                onRef={node => this.modal = node}
            />
        )
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    height: PropTypes.number,
};

export default (injectSheet(styles))(Modal);
