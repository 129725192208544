import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { App, User, RenderMode } from '@s7ui-kit/s7ui-kit';

import Navigation from './Navigation';
import OffersImages from './OfferImages';
import PageInfo from "./PageInfo";

export default combineReducers({
    App,
    Navigation,
    RenderMode,
    OffersImages,
    PageInfo,
    User,
    routing: routerReducer,
})
