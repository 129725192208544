import 'url-polyfill';
import 'whatwg-fetch';
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
const frontVersion = FRONTEND_VERSION;

const getBaseAuth = () => {
    if (typeof window === 'undefined') {
        return null;
    }

    try {
        let link = new URL(window.location.href);

        if (link.username && link.password) {
            return {
                username: link.username,
                password: link.password,
            };
        }

        return null;
    } catch (error) {
        return null;
    }
};

export default (url, options = {}, withoutContentType = false) => {

    return new Promise((resolve, reject) => {

        const oneSignalUserId = localStorage.getItem('oneSignalUserId');
        const ownOptions = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Push-Id': oneSignalUserId ?? 'denied'
            },
        };

        if(!withoutContentType) {
            ownOptions.headers['content-type'] = 'application/json';
        }

        const authData = getBaseAuth();
        let link;
        if (url.indexOf('appV=') === -1) {
            if (url.indexOf('?') !== -1) {
                if (url.indexOf('&appV=') === -1) {
                    url += `&appV=${frontVersion}`;
                }
            } else {
                if (url.indexOf('?appV=') === -1) {
                    url += `?appV=${frontVersion}`;
                }
            }
        }
        try {
            link = new URL(url);
        } catch (e) {
            link = new URL(url, window.location.origin);
        }

        if (authData) {
            ownOptions.headers['Authorization'] = `Basic ${authData.username} ${authData.password}`;
            link.username = '';
            link.password = '';
        }

        const finalOptions = Object.assign(ownOptions, options);

        fetch(link.toString(), finalOptions)
            .then(response => response.ok ? response : reject(`HttpError Status-code:${response.status};status-text:${response.statusText};url:${link.toString()}`))
            .then(response => response.text()
                .then(data => {
                    if(isEmpty(data) && !isUndefined(data)) {
                        resolve(response)
                    }

                    try {
                        const answer = JSON.parse(data);
                        resolve(answer);
                    } catch (e) {
                        reject(e);
                }})
            )
            .catch(e => {
                reject(e)
            });
    });
};
